import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Hero from "../components/hero"
import withLocation from "../components/with-location"

import EmailIcon from "../images/email-icon.svg"
import WhatsppIcon from "../images/whatsapp-icon.svg"

const Message = styled.div`
  padding: 0 1rem;
`

const Title = styled.h2`
  text-align: center;
  font-size: 2.4375em;
  font-weight: bold;
`

const Description = styled.p`
  text-align: center;
`

const Share = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const ShareMessage = styled.p`
  text-align: center;
`

const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;
  }
`

const Button = styled.a`
  display: flex;
  align-items: center;
  width: 7.5rem;
  background-color: #ffffff;
  color: #ffffff;
  text-decoration: none;
  border: 0;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 0.625em;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 420px) {
    width: 10.5rem;
    padding: 0.725rem 1rem;
    font-size: 0.75em;
    margin-bottom: 1.5rem;
  }
`

const IconWrapper = styled.div``

const ButtonText = styled.div`
  margin-left: 0.5rem;
`

const TYP = () => (
  <Layout>
    <br />
    <Hero />

    <Message>
      <Title>¡Gracias por inscribirte!</Title>
      <Description>
        Te enviaremos un email con todos los detalles del evento
      </Description>
      <Share>
        <ShareMessage>
          <strong>¡INVITA A UN AMIGO!</strong>
        </ShareMessage>
        <ShareButtons>
          <Button
            href="mailto:?subject=Vamos%20juntos%20a%20RISE%2C%20el%20evento%20de%20Trading%20m%C3%A1s%20importante%20del%20a%C3%B1o&body=Ve%20toda%20la%20informaci%C3%B3n%20en%20http://bit.ly/RISE050220"
            style={{ backgroundColor: "#3D5FDA" }}
          >
            <IconWrapper>
              <EmailIcon width="17" height="17" />
            </IconWrapper>{" "}
            <ButtonText>Invitar por Email</ButtonText>
          </Button>

          <Button
            href="https://api.whatsapp.com/send?text=Vamos%20juntos%20a%20RISE%2C%20el%20evento%20de%20Trading%20m%C3%A1s%20importante%20del%20a%C3%B1o.%20Ve%20toda%20la%20informaci%C3%B3n%20en%20http://bit.ly/2S2i0Lh"
            style={{ backgroundColor: "#72C552" }}
          >
            <IconWrapper>
              <WhatsppIcon width="17" height="17" />
            </IconWrapper>{" "}
            <ButtonText>Invitar por Whatsapp</ButtonText>
          </Button>
        </ShareButtons>
      </Share>
    </Message>
  </Layout>
)

export default withLocation(TYP)
